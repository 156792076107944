<template>
  <div class="place-order">
    <form class="form" @submit="storeInfo">
      <input
        required
        class="input"
        type="number"
        pattern="\d*"
        v-model="phone"
        @keyup="validatePhone"
        placeholder="Telefonnummer *"
      />

      <label>
        <input
          class="input"
          type="email"
          v-model="email"
          placeholder="Angiv email for at få tilsendt kvittering"
        />
      </label>

      <textarea class="textarea" v-model="comment" placeholder="Kommentar">
      </textarea>

      <div class="place-order__disclaimer" :class="disclaimerClass">
        <input
          type="checkbox"
          id="disclaimerCheckbox"
          v-model="disclaimerAccepted"
        />
        <label for="disclaimerCheckbox">
          {{ currentDisclaimerText }}
        </label>
      </div>
      <div class="buttons single">
        <button type="submit" class="button" :disabled="!disableButton">
          <i class="far fa-paper-plane"></i>SEND
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/database";

export default {
  name: "PlaceOrder",
  props: {
    msg: String,
  },
  data: function() {
    return {
      comment: "",
      phone: "",
      email: "",
      validPhone: false,
      cookieName: "userInfo",
      disclaimerAccepted: false,
      submitting: false,
      currentDisclaimerText: null,
      cookieConsent: false,
      uniqueId: null,
      wait: false
    };
  },
  computed: {
    disableButton() {
      if(!this.wait && this.validPhone){
        return true;
      }else{
        return false;
      }
    },
    disclaimerClass() {
      var aClass = "";
      if (this.submitting && !this.disclaimerAccepted) {
        aClass = "highlight";
      }
      return aClass;
    },
  },
  methods: {
    validatePhone() {
      if (this.phone && this.phone.length >= 8) {
        this.validPhone = true;
      } else {
        this.validPhone = false;
      }
    },

    storeInfo(e) {
      this.$store.state.comment = this.$data.comment;
      this.$store.state.phone = this.$data.phone;
      this.$store.state.email = this.$data.email;

      if (this.disclaimerAccepted) {
        if (!this.$store.state.codes) {
          this.$store.state.codes = [];
        }

        this.wait = true;
        this.getNewId();

        if (this.cookieConsent) {
          this.saveInCookie();
        }
      } else {
        this.submitting = true;
      }
      e.preventDefault();
    },

    writeToDb() {
      // eslint-disable-next-line no-unused-vars
      var orders = firebase.database().ref("/orders");

      let currentCodes = null;
      if (this.$store.state.codes.length) {
        currentCodes = this.$store.state.codes;
      } else {
        currentCodes = ["0000000000"];
      }

      const lCount = this.$store.state.extraL ? this.$store.state.extraL : "0";
      const aCount = this.$store.state.extraA ? this.$store.state.extraA : "0";

      //console.log(currentCodes, lCount, aCount);
      orders.push({
        phone: this.$store.state.phone,
        email: this.$store.state.email,
        comment: this.$store.state.comment,
        codes: currentCodes,
        address: this.$store.state.location,
        extra: aCount,
        extraL: lCount,
        state: 0,
        orderNumber: this.uniqueId,
        timeStamp: Date.now(),
      });
      this.$router.push("/receipt");
    },

    getNewId() {
      var ref = firebase.database().ref("/id");
      const vm = this;

      ref.transaction(
        function(current_value) {
          if (current_value === null) {
            return 0;
          } else {
            return current_value + 1;
          }
        },
        function(error, committed, snapshot) {
          if (error) {
            console.log("Transaction failed abnormally!", error);
            this.wait = false;
          } else if (!committed) {
            console.log("We aborted the transaction");
            this.wait = false;
          } else {
            //console.log("counter incremented");
            vm.uniqueId = snapshot.val();

            if (vm.uniqueId) {
              vm.writeToDb();
            }
          }
        }
      );
    },

    saveInCookie() {
      const userInfo = {
        phone: this.phone,
        email: this.email,
        location: this.$store.state.location,
      };
      this.$cookie.setCookie(this.cookieName, userInfo);
    },

    getFromCookie() {
      const userInfo = this.$cookie.getCookie(this.cookieName);
      if (userInfo) {
        this.phone = userInfo.phone;
        this.email = userInfo.email;
      }
      this.validatePhone();
    },

    getDisclaimerContent() {
      const vm = this;
      var disclaimer = firebase.database().ref("/content/disclaimer");
      console.log("disclaimer:",disclaimer)
      disclaimer.on("value", (snapshot) => {
        const data = snapshot.val();

        if (Object.values(data).length) {
          vm.currentDisclaimerText = Object.values(data)[0].text;
        }
      });
    },
  },
  mounted() {
    // console.log(this.$store.state);

    if (this.$store.state.codes === undefined) {
      this.$router.push("/");
    } else {
      this.getDisclaimerContent();

      if (window.Cookiebot) {
        this.cookieConsent = window.Cookiebot.consent.necessary;
        if (this.cookieConsent) {
          this.getFromCookie();
        }
      }
    }
  },
};
</script>

<style scoped lang="scss">
.place-order {
  width: 100%;
  &__disclaimer {
    margin: 1rem 0;
    display: flex;
    &.highlight {
      color: red;
    }
    label {
      padding-left: 0.5rem;
      font-size: 0.8rem;
    }
  }
}
</style>
