<template>
  <div class="container order">
    <PlaceOrder msg="Bestil afhentning" btnText="SEND" />
  </div>
</template>

<script>
// @ is an alias to /src
import PlaceOrder from "@/components/PlaceOrder.vue";
//import Back from "@/components/Back.vue";

export default {
  name: "Order",
  components: {
    PlaceOrder,
    //Back,
  },
};
</script>
